import React from "react"
import "../../styles/bulma-styles.scss"

import MaterialCustomizerComponent from "../../compositions/Solutions/materials-costimizer";


const MaterialCustomizer = () => (
  <MaterialCustomizerComponent />
)

export default MaterialCustomizer
